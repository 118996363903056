<template>
  <section class="section-block">
    <SectionTitle title="商城發票設定" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="200px">
      <el-form-item label="開放串接第三方發票">
        <div class="secret-text collapse">
          <span>{{ displayData.enable }}</span>
        </div>
      </el-form-item>

      <el-form-item label="第三方串接發票">
        <div class="secret-text collapse">
          <span>{{ displayData.platform }}</span>
        </div>
      </el-form-item>
    </el-form>

    <MemberShopInvoiceConfigEditModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, ref, computed, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { invoice3rdPlatformConfig } from '@/config/memberShop'
import { get } from 'lodash'
import MemberShopInvoiceConfigEditModal from './MemberShopInvoiceConfigEditModal.vue'

export default defineComponent({
  name: 'MemberShopInvoiceConfigBlock',
  components: { SectionTitle, MemberShopInvoiceConfigEditModal },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props) {
    const displayData = computed(() => {
      const config = props.configData
      return {
        enable: config.enable ? '啟用' : '關閉',
        platform: get(invoice3rdPlatformConfig, `${config.platform}.label`) || '尚未設置',
      }
    })
    const modal = reactive({
      edit: false,
    })
    return { displayData, modal }
  },
})
</script>

<style lang="postcss" scoped>

</style>
