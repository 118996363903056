<template>
  <div class="member-shop-invoice-settings">
    <PageTitle
      title="發票設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'MemberShopParameterSetting' })"
    />
    <MemberShopInvoiceConfigBlock
      v-loading="loading"
      :configData="invoiceConfigData"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { GetMemberShopInvoiceConfig } from '@/api/memberShop'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberShopInvoiceConfigBlock from './MemberShopInvoiceConfigBlock.vue'
import store from '@/store'

export default defineComponent({
  name: 'MemberShopIvoiceSetting',
  components: { PageTitle, MemberShopInvoiceConfigBlock },
  setup (props) {
    const loading = ref(false)
    const shopId = computed(() => store.getters.shop)
    const invoiceConfigData = ref({})

    const getPaymentConfig = async () => {
      const [res, err] = await GetMemberShopInvoiceConfig({ shopId: shopId.value })
      if (err) window.$message.error(err)
      invoiceConfigData.value = res
    }

    const refresh = async () => {
      if (loading.value) return
      loading.value = true
      await getPaymentConfig()
      loading.value = false
    }

    onMounted(async () => {
      await refresh()
    })
    return { refresh, invoiceConfigData, loading }
  },
})
</script>

<style scoped lang="postcss">

</style>
